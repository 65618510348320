import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { cn } from "../lib/helpers";

import PortableText from "./portableText";
import shieldBlank from "../images/shield-blank.svg";

import { bodyTextBlock } from "./typography.module.css";
import styles from "./contact-page.module.css";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ContactPage = (props) => {
  const {
    contactTitle,
    email,
    mainContactEmail,
    mainContactName,
    mainContactTitle,
    phone,
    _rawContactBodyCopy,
  } = props;

  const { register, handleSubmit, errors } = useForm();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const onSubmit = (data, event) => {
    const form = event.target;
    form.reset();
    const request = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...data,
      }),
    };
    fetch("/", request)
      .then(submitSuccess())
      .catch((error) => alert(error));
  };

  const submitSuccess = () => {
    setFormSubmitted(true);
  };

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.formInfo}>
          <div className={styles.companyContact}>
            <div className={styles.companyInfo}>
              <div className={styles.location}>Los Angeles + Research Triangle</div>
              {phone && <div className={styles.phone}>{phone}</div>}
              {email && (
                <a className={styles.companyEmail} href={`mailto:${email}`}>
                  {email}
                </a>
              )}
            </div>
            <div className={cn(styles.mainContact)}>
              <div>
                {mainContactName && (
                  <span className={styles.mainContactName}>{mainContactName}</span>
                )}
                {mainContactTitle && (
                  <span className={styles.mainContactTitle}>, {mainContactTitle}</span>
                )}
              </div>
              {mainContactEmail && (
                <a className={styles.mainContactEmail} href={`mailto:${mainContactEmail}`}>
                  {mainContactEmail}
                </a>
              )}
            </div>
          </div>
          <div className={styles.description}>
            {contactTitle && <h2>{contactTitle}</h2>}
            {_rawContactBodyCopy && (
              <div className={cn(bodyTextBlock, styles.contactBodyCopy)}>
                <PortableText blocks={_rawContactBodyCopy} />
              </div>
            )}
          </div>
        </div>
        <form
          name="contact"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className={styles.form}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label>
              Don’t fill this out: <input name="bot-field" />
            </label>
          </div>
          <div className={styles.formRow}>
            <label>
              <span className={styles.fieldLabelCopy}>Name</span>
              <input type="text" name="name" ref={register({ required: true })} />
              {errors.name && <span className="error">Please enter your name</span>}
            </label>
            <label>
              <span className={styles.fieldLabelCopy}>Email</span>
              <input type="email" name="email" ref={register({ required: true })} />
              {errors.email && <span className="error">Please enter an email</span>}
            </label>
          </div>
          <label>
            <span className={styles.fieldLabelCopy}>Subject</span>
            <input type="text" name="subject" ref={register} />
          </label>
          <label>
            <span className={styles.fieldLabelCopy}>Message</span>
            <textarea name="message" ref={register({ required: true })} />
            {errors.message && <span className="error">Please enter a message</span>}
          </label>
          <div className={styles.formRow}>
            {email && (
              <div className={styles.afterFormRow}>
                <img className={styles.shieldBlank} src={shieldBlank} alt="Shield" />
                <div>
                  <span>Sending to: </span>
                  <a className={styles.mainContactEmail} href={`mailto:${email}`}>
                    {email}
                  </a>
                </div>
              </div>
            )}
            <button
              className={cn(styles.submitForm, formSubmitted && styles.formSubmitted)}
              type="submit"
            >
              {!formSubmitted ? "Send" : "Thanks!"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ContactPage;
