import React from "react";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import QContactForm from "../queries/QContactForm";
import ContactPage from "../components/contact-page";

const Contact = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <QContactForm>
      {({ site }) => (
        <Layout>
          <SEO title="Contact" />
          <ContactPage {...site} />
        </Layout>
      )}
    </QContactForm>
  );
};

export default Contact;
