import React from "react";
import { StaticQuery, graphql } from "gatsby";

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={({ site }) => {
      return children({ site });
    }}
  />
);
const query = graphql`
  query ContactFormQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      email
      phone
      mainContactName
      mainContactEmail
      mainContactTitle
      contactTitle
      _rawContactBodyCopy(resolveReferences: { maxDepth: 5 })
    }
  }
`;
